<template>
  <div>
    <v-row>
      <v-col cols="4">
        <v-select :items="['Date', 'Package Name', 'Phone', 'Status']" label="Filters" outlined dense multiple required
          chips v-model="filterItems" prepend-icon="" append-icon="mdi-filter"></v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field outlined dense type="date" v-show="filterItems.includes('Date')" v-model="filterDate" required
          label="Date"></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field outlined dense v-model="filterByPackageName" v-show="filterItems.includes('Package Name')" required
          label="Package Name" append-icon="mdi-package"></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field outlined dense v-show="filterItems.includes('Phone')" v-model="filterByPhone" required
          label="Mobile Number" append-icon="mdi-package"></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-text-field outlined dense v-show="filterItems.includes('Status')" v-model="filterByStatus" required
          label="Status" append-icon="mdi-package"></v-text-field>
      </v-col>
    </v-row>
    <v-data-table :headers="headers" :items="filterFinalData" sort-by="calories" class="elevation-3" :search="search">
      <template v-slot:no-data>
        <v-progress-circular v-if="loading" indeterminate color="secondary"></v-progress-circular>
        <v-icon v-else x-large color="grey lighten-1">
          mdi-tray-remove
        </v-icon>

      </template>
    </v-data-table>
  </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import * as moment from 'moment'
export default {
  name: 'Packages',
  mixins: [validationMixin],
  data: () => ({
    selectedPackage: '',
    transactionList: [],
    editedIndex: -1,
    search: '',
    editedItem: {
      filters: [],
      date: null,
      packageName: null
    },
    filterItems: [],
    filterByPackageName: '',
    filterDate: '',
    filterByPhone: '',
    filterByStatus: '',
    defaultItem: {
      filters: [],
      date: null,
      packageName: null,
      status: null,
    },
    headers: [
      {
        text: 'Purchase ID',
        value: 'id',
      },
      { text: 'Customer Name', value: 'user_name' },
      { text: 'Customer Phone', value: 'user_phone' },
      { text: 'Package Name', value: 'package_name' },
      { text: 'Credits', value: 'package_credits' },
      { text: 'Package Amount', value: 'package_amount' },
      { text: 'Promocode Amount', value: 'promocode' },
      { text: 'Final Amount', value: 'amount' },
      { text: 'Date', value: 'created_at' },
      { text: 'Status', value: 'status' },
      { text: 'Method', value: 'payment_method' },
    ],
    data: [],

  }),
  created() {
    this.getPackages();
  },
  methods: {
    //Packages API Calls
    getPackages() {
      this.loading = true
      this.$Axios
        .get('/api/v1/transaction')
        .then((res) => {
          console.log(res.data)
          this.data = res.data.filter((data) => data.package_name !== null && data.package_name !== undefined)
          // set the status to Pending/ Complete from true/false
          this.data.forEach((data) => {
            if (data.status === true) {
              data.status = 'Complete'
            } else {
              data.status = 'Pending'
            }
          })
        })
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false
        })
    },
    //API for filter
    // filterPackages() {
    //   //tempdate: (value) => { return moment(value, 'YYYY-MM-DD', true).isValid()}
    //   this.loading = true
    //   //moment(this.editedItem.date).format('YYYY-MM_DD')
    //   this.$Axios
    //     .post(`filter-transcations?transactionID=1&date=${this.editedItem.date}&packageName=${this.editedItem.packageName}`)
    //     .then(({data}) => {
    //       console.log(this.editedItem.date)
    //       console.log(this.editedItem.packageName)
    //       console.log(data.Details)
    //       this.data = data.Details;

    //     })
    //     .catch((e) => {
    //       console.log(e);
    //     })
    //     .finally(() => {
    //        this.loading = false
    //     })
    // },

  },
  computed: {
    filterFinalData() {
      if (this.filterItems.length === 0) {
        return this.data
      } else {
        //   //moment(this.editedItem.date).format('YYYY-MM_DD')
        let data = this.data
        if (this.filterItems.includes('Date') && this.filterDate) {
          data = data.filter((data) => data.created_at === moment(this.filterDate).format('DD-MM-YYYY'))
        }
        if (this.filterItems.includes('Package Name') && this.filterByPackageName) {
          data = data.filter((data) => data.package_name?.toLowerCase()?.includes(this.filterByPackageName.toLowerCase()))
        }
        if (this.filterItems.includes('Phone') && this.filterByPhone) {
          data = data.filter((data) => data.user_phone?.toLowerCase()?.includes(this.filterByPhone.toLowerCase()))
        }
        if (this.filterItems.includes('Status') && this.filterByStatus) {
          data = data.filter((data) => data.status?.toLowerCase()?.includes(this.filterByStatus.toLowerCase()))
        }
        return data
      }
    }

  }

};
</script>