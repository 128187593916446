<template>
  <div>


    <v-tabs color="primary">
      <v-tab>Package</v-tab>
      <v-tab>Background Removal</v-tab>

      <v-tab-item :key="1">
        <v-container fluid>
          <transaction-packages />
        </v-container>
      </v-tab-item>
      <!-- <v-tab-item :key="2">
        <v-container fluid>
          <transaction-templates />
        </v-container>
      </v-tab-item> -->
      <v-tab-item :key="3">
        <v-container fluid>
          <BgPurchaseDetails />
        </v-container>
      </v-tab-item>
    </v-tabs>

  </div>
</template>

<script>
import TransactionPackages from '../components/TransactionPackages'
import BgPurchaseDetails from '../components/BgPurchaseDetails.vue'

export default {
  name: 'Transations',

  components: {
    TransactionPackages,
    BgPurchaseDetails
  },
}
</script>